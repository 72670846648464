import React from "react";

import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

import productA from '../../../images/12-1032.BO_.png'
import productB from '../../../images/12-1042.NG_.png';
import productC from '../../../images/25-2302-01-1.png';
import productD from '../../../images/13-1859_jabonera.png';

export function GridProduct() {
  return (
      <Container fluid className="py-5">
        <Container className="py-5">
            <Row className="justify-content-center">
                <Col className="col-lg-6 col-md-8 col text-center mb-4">
                    <h6 className="font-weight-normal text-uppercase mb-3" style={{color: '#1c3c83'}}>Productos</h6>
                    <h1 className="mb-4">Trabajamos con las mejores marcas del mercado</h1>
                </Col>
            </Row>
            <Row className="mx-1">
                <Col className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="overflow-hidden border">
                        <div className="d-flex align-items-center justify-content-center p-3">
                            <img className="img-fluid" src={productA} alt="" />
                        </div>
                    </div>
                </Col>
                <Col className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="overflow-hidden border">
                        <div className="d-flex align-items-center justify-content-center p-3">
                            <img className="img-fluid" src={productB} alt="" />
                        </div>
                    </div>
                </Col>
                <Col className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="overflow-hidden border">
                        <div className="d-flex align-items-center justify-content-center p-3">
                            <img className="img-fluid" src={productC} alt="" />
                        </div>
                    </div>
                </Col>
                <Col className="col-12 col-md-4 col-lg-3 mb-4">
                    <div className="overflow-hidden border">
                        <div className="d-flex align-items-center justify-content-center p-3">
                            <img className="img-fluid" src={productD} alt="" />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mt-5 text-center" xl={{span: 4, offset: 4}}>
                    <Link to="/productos"><Button size="lg" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83'}}>Ver productos</Button></Link>
                </Col>     
            </Row>
        </Container>
    </Container>
  )
}