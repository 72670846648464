import axios from 'axios';

export const api = axios.create({ baseURL: process.env.SISTEMA_URL });

const getGeneralToken = async () =>  {
    const response = await axios.post(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/login`, {
        param1: `${process.env.REACT_APP_PUBLIC_PARAM_UNO}`,
        param2: `${process.env.REACT_APP_PUBLIC_PARAM_DOS}`,
        empresaId: process.env.REACT_APP_PUBLIC_EMPRESA_ID
    })
    return response.data.token
}

api.interceptors.request.use(
    async (config) => {
        const token = await getGeneralToken()

        if(token) {
            config.headers.token = token
        }
        return config
    }
)