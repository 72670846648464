import React, { useState, useEffect } from "react";
import { Button, Carousel, Container } from "react-bootstrap";

import { Link } from 'react-router-dom';

import backgroundImagePrimary from '../../../images/DJI_0222.JPG';
import backgroundImageSecondary from '../../../images/fondo_local.png';
import backgroundMini from '../../../images/20230203_142226_HDR.jpg'

const carouselCaption = {
    top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	zIndex: 1
}

export function Carrousel() {

    const [width, setWindowWidth] = useState(0)

    useEffect(() => {
        updateDimensions();

        window.addEventListener("resize", updateDimensions);

        return () => 
            window.removeEventListener("resize", updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const responsive = {
        showBottomNavMenu: width > 769
    }

  return (
    <Container fluid className="px-0" style={{paddingTop: '110px'}}>
        <Carousel interval={5000} id="header-carousel" className="slide" data-ride="carousel">
                <Carousel.Item>
                    {responsive.showBottomNavMenu ? 
                        <img className="w-100" style={{height: '90vh'}} src={backgroundImagePrimary} alt="Testing 1" /> :
                        <img style={{height: '80vh'}} src={backgroundImagePrimary} alt="Testing 1" />
                    }
                    <Carousel.Caption className="d-flex flex-column align-items-center justify-content-end" style={carouselCaption}>
                        <div className="p-3" style={{maxWidth: '800px'}}>
                            <h4 className="text-uppercase font-weight-normal mb-md-3" style={{ color: "white"}}>EyC Distribuidora</h4>
                            <h3 className="display-3 mb-md-4" style={{ color: "white"}}>Tenemos todo lo necesario para su obra</h3>
                            <Link to="/productos">
                                <Button className="py-md-3 px-md-5 mb-2 mb-md-4" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83'}}>Ver productos</Button>
                            </Link>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    {responsive.showBottomNavMenu ? 
                        <img className="w-100" style={{height: '90vh'}} src={backgroundImageSecondary} alt="Testing 2" /> : 
                        <img className="w-100" style={{height: '80vh'}} src={backgroundMini} alt="Testing 2" />
                    }
                    <Carousel.Caption className="d-flex flex-column align-items-center justify-content-end" style={carouselCaption}>
                        <div className="p-3" style={{maxWidth: '800px'}}>
                            <h4 className="text-uppercase font-weight-normal mb-md-3">EyC Distribuidora</h4>
                            <h3 className="display-3 mb-md-4">Las mejores marcas confian en nosotros.</h3>
                            <Link to="/productos">
                                <Button className="py-md-3 px-md-5 mb-2 mb-md-4" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83'}}>Ver productos</Button>
                            </Link>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
        </Carousel>
    </Container>
  )
}