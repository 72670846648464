import React from "react";
import { Helmet } from "react-helmet-async";

import { Carrousel } from "../component/pure/home/carrousel";
import { AboutUs } from "../component/pure/home/aboutUs";
import { OurService } from "../component/pure/home/ourService";
import { WhyUs } from "../component/pure/home/whyUs";
import { Testimonials } from "../component/pure/home/testimonial";
import { GridProduct } from "../component/pure/home/gridProduct";

export function Home() {
  return(
    <>
      <Helmet>
        <title>EyC</title>
      </Helmet>
      <Carrousel />
      <AboutUs />
      <OurService />
      <WhyUs />
      <GridProduct />
      <Testimonials />
    </>
  )
}