import { useRef, useContext } from 'react';
import { Col, Row, Button } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { SearchFilter } from './searchFilter';
import { AppliedFilter } from './appliedFilter';
import { RubroFilter } from './rubroFilter';

import { FiltersContext } from '../../../context/FilterContext'
import { useRubros } from '../../../hooks/useRubros'

export function SidebarFilter({ rubros }) {

  const menuOverlay = useRef(null)

  const { state, addOrRemoveSubrubro, removeSubrubro, isSubrubroSelected, removeTitulo, addTitulo } = useContext(FiltersContext)
  const { subRubros, titulo } = state
  const { getNombreSubrubro } = useRubros(rubros)

  return (
    <section>
      <div className='btn-collapse_container'>
        <Button
          className={'btn-orange'}
          onClick={() => {
            menuOverlay.current.style.width = '100%'
          }}>
          <FontAwesomeIcon icon={faSearch} size={'1x'}/>
        </Button>
      </div>
      <div ref={menuOverlay}
        className="overlay"
      >
        <a className='closebtn'
          onClick={() => {
            menuOverlay.current.style.width = '0%'
          }}>&times;</a>
        <div className='overlay-content'>
          <div className={'filters'}>
            <Row>
              <Col className='col-10 offset-1 col-lg-12 offset-lg-0'>
                <div className={'filters_search-container'}>
                  {
                    titulo && <AppliedFilter text={titulo} onClose={() => removeTitulo()} />
                  }
                  <SearchFilter value={titulo} onChange={addTitulo} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='col-10 offset-1 col-lg-12 offset-lg-0'>
                <div className={'filters_rubros-container'}>
                  {
                    subRubros.map(idSubrubro =>
                      <AppliedFilter
                        key={idSubrubro}
                        text={getNombreSubrubro(idSubrubro)}
                        onClose={() => removeSubrubro(idSubrubro)}
                      />)
                  }
                  <RubroFilter
                    onSelect={addOrRemoveSubrubro}
                    isSubrubroSelected={isSubrubroSelected}
                    rubros={rubros}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='col-xs-12'>
                  <div className='btn-search_container'>
                    <Button
                      onClick={() => {
                      menuOverlay.current.style.width = '0%'
                    }}>BUSCAR</Button>
                  </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <style jsx="true">{`
              .overlay {
                width: 100%;
              }

              .btn-search_container,
              .closebtn {
                display: none;
                cursor: pointer
              }
              
              .filters {
                margin-top: 2rem;
              }
              
              .filters_search-container,
              .filters_rubros-container,
              .filters_tags-container {
                margin-top: 2.3rem
              }

              .btn-collapse_container {
                display: none;
              }

              .btn-collapse_container > button {
                background-color: #1C3C83;
              }
              
              @media (min-width: 768px) and (max-width: 992px) { 
                .btn-collapse_container {
                  top: 405px !important;
                  right: 35px !important;
                }  
              }
              
              @media (min-width: 575.98px) and (max-width: 767.98px) {
                .btn-collapse_container {
                  top: 465px !important;
                  right: 35px !important;
                }
              }

              @media (max-width: 991.98px) {
                .overlay {
                  height: 100%;
                  width: 0;
                  position: fixed;
                  z-index: 1031;
                  top: 0;
                  left: 0;
                  background-color: white;
                  overflow-x: hidden;
                  transition: 0.5s;
                }

                .overlay-content {
                  position: relative;
                  top: 5%;
                  width: 100%;
                }

                .overlay .closebtn {
                  position: absolute;
                  right: 28px;
                  font-size: 60px;
                  display: block;
                  text-decoration: none;
                  color: #1C3C83;
                }
                
                .btn-search_container {
                  display: block;
                }

                .btn-search_container > button {
                  background-color: #1C3C83;
                  border: none;
                }
                
                .filters {
                  margin-top: 0;
                }
                
                .filters_search-container,
                .filters_rubros-container,
                .filters_tags-container {
                  margin-top: 1rem
                }

                .btn-collapse_container {
                  display: flex;
                  justify-content: flex-end;
                  position: fixed;
                  right: 8px;
                  top: 460px;
                  z-index: 1000;
                }
                
                .btn-search_container {
                  display: flex;
                  justify-content: center;
                  margin: 20px 0;
                }
              }
            `}</style>
    </section>
  )
}