import { useReducer } from "react";

import { FiltersReducer } from "../context/FiltersReducer";

export const filtersInitialState = {
    titulo: '',
    tags: '',
    rubros: [],
    subRubros: [],
    grupoArticulos: []
}

export const useFilters = () => {
    const [state, dispatch] = useReducer(FiltersReducer, filtersInitialState)

    const addOrRemoveSubrubro = (idsubrubro) => {
        if(isSubrubroSelected(idsubrubro)) {
            removeSubrubro(idsubrubro)
        } else {
            addSubrubro(idsubrubro)
        }
    }

    const addSubrubro = (idsubrubro) => {
        dispatch({ type: 'addSubrubro', payload: idsubrubro })
    }

    const removeSubrubro = (idsubrubro) => {
        dispatch({ type: 'removeSubrubro', payload: idsubrubro })
    }

    const addTitulo = (word) => {
        dispatch({ type: 'addTitulo', payload: word })
    }

    const removeTitulo = (word) => {
        dispatch({ type: 'removeTitulo' })
    }

    const isSubrubroSelected = (idsubrubro) => state.subRubros.includes(idsubrubro)

    return {
        state,
        addOrRemoveSubrubro,
        removeSubrubro,
        isSubrubroSelected,
        addTitulo,
        removeTitulo
    }
}