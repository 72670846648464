import { createContext } from "react";
import { useFilters } from '../hooks/useFilters'

export const FiltersContext = createContext()

export const FilterProvider = ({children})  => {
    const { state, removeSubrubro, addTitulo, removeTitulo, addOrRemoveSubrubro, isSubrubroSelected } = useFilters()
    
    return (
        <FiltersContext.Provider value={{
            state,
            addTitulo,
            removeTitulo,
            addOrRemoveSubrubro,
            removeSubrubro,
            isSubrubroSelected
        }}>
            {children}
        </FiltersContext.Provider>
    )
}