import React from 'react'

import { Collapse } from 'react-bootstrap'

import { useRubros } from '../../../hooks/useRubros'

const linkStyle = {
  cursor: "pointer",
}

export function RubroFilter({ rubros, onSelect, isSubrubroSelected }) {
  const { rubrosWithExpanded, isRubroExpanded, updateRubroExpanded } = useRubros(rubros)

  return (
    <>
      <div className={'categories'}>
        <h5 className='mb-4'>CATEGORÍAS</h5>
        <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
          {
            rubrosWithExpanded.map(({ nombre, id, subrubros }) => {
              return (
                <li key={nombre} style={{ position: 'relative', padding: '0 0 8px 0', margin: '0 0 8px 0', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }} >
                  <a onClick={() => updateRubroExpanded(id)} style={linkStyle}>{nombre}</a>
                  <Collapse in={isRubroExpanded(id)}>
                    <ul style={{ listStyle: 'none', margin: '0', padding: '0', paddingTop: '0.5rem' }}>
                      {
                        subrubros.map(({ nombre, id }) => (
                          <li key={id} className={'subrubros__item'}>
                            <label className={'subrubros__label'}>
                              <input
                                className={'subrubros__input'}
                                checked={isSubrubroSelected(id)}
                                onChange={() => onSelect(id)}
                                name="isGoing"
                                type="checkbox" />
                              {nombre}
                            </label>
                          </li>
                        ))
                      }
                    </ul>
                  </Collapse>
                </li>
              )
            })
          }
        </ul>
      </div>
      <style jsx='true'>{`
                div.categories {
                    position: sticky;
                    top: 10px;
                  }

              .subrubros__item {
                  border-bottom: 0;
                  color: #6f6f6f;
                  cursor: pointer;
                  font-size: .875rem;
                  margin-left: .8rem;
                  margin-bottom: 0;
                  padding: .5rem;
                }

              .subrubros__item:hover,
              .subrubros__item:active,
              .subrubros__item:focus {
                //border-left: 1px solid blue;
              }
              
              .subrubros__label {
                font-weight: initial;
                margin: 0;
              }
              
              .subrubros__input{
                position: relative;
                top: 2px;
                margin-right: 5px;
              }     
            `}</style>
    </>
  )
}