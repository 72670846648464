import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AboutUs } from "../component/pure/home/aboutUs";
import { PageHeader } from "../component/pure/home/pageHeader";
import { WhyUs } from "../component/pure/home/whyUs";

import logo from '../../src/images/logo.jpg';
import { Helmet } from "react-helmet-async";

const containImg = {
  width: '75%',
  height: 'auto',
  objectFit: 'cover',
  overflow: 'hidden'
}

export function About() {
  return (
    <>
      <Helmet>
        <title>EyC - Nosotros</title>
      </Helmet>
      <PageHeader section='Nosotros' />
      <AboutUs />
      <Container>
        <Container className="py-5">
          <Row>
            <Col className="col-12 col-md-7 mt-5 py-5 pr-lg-5">
              <h6 className="font-weight-normal text-uppercase mb-3" style={{ color: '#1c3c83' }}>Institucional</h6>
              <div className="position-relative">
                <div className="sectionTitleBefore"></div>
                <h1 className="mb-4 sectionTitle">Nuestra historia</h1>
              </div>
              <p>EyC SRL, materiales para la construcción. La distribuidora dio sus primeros pasos en marzo del 2005 en un pequeño predio sobre la Ruta Nacional N° 7 con la comercialización de cemento, cales, ladrillos cerámicos, viguetas y otros. Con el correr del tiempo se instaló en el Parque Industrial de Junín con más de 1000 m2 cubiertos de depósito para aumentar el almacenamiento de la mercadería. La incorporación de una flota de camiones para hacer diferentes tipos de entregas y la amplitud en las instalaciones permitió incorporar una mayor cantidad de productos y mejorar el servicio para poder llegar a más destinos.</p>
              <p>En la actualidad, la empresa familiar con sus dos generaciones son las que atienden a clientes de la ciudad y de la zona para brindarles el mayor asesoramiento y soluciones para la correcta utilización de los materiales suministrados.</p>
              <p>Con el apoyo de importantes proveedores del sector EyC SRL llega con su logística a diferentes localidades de la provincia de Buenos Aires dando un servicio de primera calidad a la hora de entregar los materiales.</p>
              <p>Con más de 15 años, EyC SRL agradece a todos nuestros clientes y los invita a seguir creciendo juntos en este camino de la comercialización de materiales para la construcción.</p>
            </Col>
            <Col className="col-12 col-md-5 d-flex flex-column justify-content-center">
              <div className="text-center">
                <img style={containImg} src={logo} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <WhyUs hidden='true' />

      <style jsx="true">{`
        .img-logo {
          width: 350px;
          height: auto;
        }
        
        .sectionTitleBefore  {
          position: absolute;
          content: '';
          top: 10px;
          left: 0;
          width: 45px;
          height: 3px;
          background: #1c3c83
        }

        .sectionTitle {
          position: relative;
          padding-left: 60px
        }
      `}</style>
    </>
  )
}