import { api } from "../api/api";

import { filtersInitialState } from "./useFilters";

export const useApi = () => {
    const getRubros = async () => {
        const response = await api.get(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/rubros`)

        const rubros = await Promise.all(response.data.map(async (rubro) => {
            const subrubros = await getSubrubros(rubro.id)
            return await ({
              ...rubro,
              subrubros: [
                ...subrubros
              ]
            })
          }))

        return rubros
    }

    const getSubrubros = async (rubroId) => {
        const response = await api.get(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/subrubros?rubroId=${rubroId}`)
        return response.data
    }

    const getArticulos = async (filters = filtersInitialState, limit = 9, offset = 0) => {
        const response = await api.post(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/articulosweb?offset=${offset}&limit=${limit}`, {
            ...filters
        })
        return response.data
    }

    const getArticulo = async (slug) => {
        const response = await api.get(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/articulo/slug/${slug}`)
        return response.data
    }

    return {
        getRubros,
        getArticulos,
        getArticulo
    }
}