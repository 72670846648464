import React from "react";
import { Link } from "react-router-dom";

const linkStyle = {
    textDecoration: "none",
    color: "black",
    margin: "0"
}

const MailTo = ({ mailto, label }) => {
    return (
        <Link
            to=''
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
            style={linkStyle}
        >
            {label}
        </Link>
    );
};

export default MailTo;