export const FiltersReducer = (state, action) => {
    switch (action.type) {
        case 'addSubrubro':
            return {
                ...state,
                subRubros: [
                    ...state.subRubros,
                    action.payload
                ]
            }
        case 'removeSubrubro':
            return {
                ...state,
                subRubros: state.subRubros.filter(subrubro => subrubro !== action.payload)
            }
        case 'addTitulo':
            return {
                ...state,
                titulo: action.payload
            }
        case 'removeTitulo':
            return {
                ...state,
                titulo: ''
            }
        default: 
            return state
    }
}