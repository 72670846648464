import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export function AppliedFilter ({text, onClose}) {
    return (
        <>
            <div className="label mb-3">
                {text}
                <FontAwesomeIcon
                    icon={faTimes}
                    size={'1x'}
                    onClick={() => onClose()}
                    style={{ marginLeft: 5 }}
                />
            </div>
            <style jsx="true">{`
                .label {
                    display: inline-block;
                    background-color: #1C3C83;
                    color: white;
                    margin-right: 0.875rem;
                    margin-bottom: 0.875rem;
                    padding: 0.5rem 1rem;
                    border-radius: 10px;
                    text-transform: uppercase;
                    font-size: 14px;
                }
            `}
            </style>
        </>
    )
}