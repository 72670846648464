import React from "react";

import { Carousel, Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBuilding, faEnvelopeOpenText, faPhone } from '@fortawesome/free-solid-svg-icons';

import MailTo from '../MailTo';
import LinkTel from "../LinkTel";

export function BottomBar({showBottomNavMenu}) {

    const showNav = {
        display: showBottomNavMenu ? "flex" : 'none',
        boxShadow: showBottomNavMenu ? 'rgb(0 0 0 / 47%) 0px 8px 5px 0px' : 'none'
    }

  return (
    <Container fluid className="bg-white py-3" style={showNav}>
        <Carousel interval={5000} id="bottomBarCarousel" className="slide" data-ride="carousel" variant="dark" indicators={false} style={{ minWidth: "100%"}}>
            <Carousel.Item>
                <Container>
                    <Row className="row">
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faBuilding} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestro local</h5>
                                    <p className="m-0">Av. Dr. Benito de Miguel 820</p>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestro Correo</h5>
                                    <MailTo 
                                        label="ventaseycdistribuidora@gmail.com" 
                                        mailto="mailto:ventaseycdistribuidora@gmail.com"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faPhone} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestros numeros</h5>
                                    <p className="m-0">
                                        <LinkTel mobile="+54 9 2364 154673657" showTel="2364 673657 "/> || 
                                        <LinkTel mobile="+54 9 2364 154674811" showTel=" 2364 674811"/>
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
           
            <Carousel.Item>
                <Container>
                    <Row className="row">
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faBuilding} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestro corralón</h5>
                                    <p className="m-0">D. Muscariello Esq. R. Riva</p>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faEnvelopeOpenText} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestro Correo</h5>
                                    <MailTo
                                        label="eycdistribuidora@gmail.com"
                                        mailto="mailto:eycdistribuidora@gmail.com"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="col-lg-4 text-left text-lg-center mb-3 mb-lg-0">
                            <div className="d-inline-flex align-items-center text-start">
                                <FontAwesomeIcon icon={faPhone} size={'2x'} color='#1c3c83' className="m-0 me-3"/>
                                <div className="d-flex flex-column">
                                    <h5>Nuestros numeros</h5>
                                    <p className="m-0">
                                        <LinkTel mobile="+54 9 2364 15455526" showTel="2364 455526 " /> || 
                                        <LinkTel mobile="+54 9 2364 15674811" showTel=" 2364 674811" />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Carousel.Item>
        </Carousel>
    </Container>
  )
}