import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { Button } from 'react-bootstrap';

import { useApi } from "../hooks/useApi";

import { PageHeader } from "../component/pure/home/pageHeader";
import { Container, Row, Col } from "react-bootstrap";

export function Product() {
  const { slug } = useParams();
  const [product, setProduct] = useState({})

  const { getArticulo } = useApi();

  const getNewArticulo = async () => {
    const article = await getArticulo(slug);
    setProduct(article);
  }

  useEffect(() => {
    getNewArticulo()
  }, []);

  return (
    <>
      <PageHeader section="Productos" isASubCategory="true" subCategory={product.subRubro} slugCategory="true" />
      <Container>
        <Row className="product">
            <Col className="col-12 col-md-7 d-flex flex-column justify-content-center">
            <div className={'product_image-container'}>
                  <div className={'product-image'} 
                    style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_SISTEMA_IMAGE_URL}${product.imagenPrincipal})` }}>
                  </div>
                </div>
            </Col>
            <Col className="col-12 col-md-5">
              <div className="product__details">
                <p className="product__subrubro">{product.subRubro}</p>
                <h3 className="product__title">{product.titulo}</h3>
                <h4 className="product__description">{product.descripcionWeb}</h4>
              </div>
              <Link to="/productos">
                  <Button className="mb-5 mb-md-0" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83', marginTop: '70px'}}>&larr; Volver a la tienda</Button>
                </Link>
            </Col>
        </Row>
      </Container>

      <style jsx='true'>{`
              .product {
                min-height: 500px;
              }

              .product-image {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 300px;
            }

              .product__details {
                margin-top: 70px;
              }

              .product__image-container {
                display: flex;
                margin: 0 auto;
                min-height: 400px;
                position: relative;
                width: 80%;
                justify-content: center;
                align-items: center;
              }

              .product__title {
                font-family: Roboto, sans-serif;
                font-size: 32px;
                line-height: 38px;
                margin-bottom: 20px;
              }

              .product__price {
                color: black;
                font-family: Roboto, sans-serif;
                font-size: 18px;
                line-height: 24px;
                font-weight: 700;
                margin-bottom: 20px;
              }

              .product__description {
                font-size: 14px;
                font-family: Montserrat, sans-serif;
              }

              .product__subrubro {
                color: black;
                font-family: Roboto, sans-serif;
                font-size: 12px;
                font-style: italic;
                text-transform: capitalize;
                margin-bottom: 20px;
              }
            `}</style>
    </>
  )
}