import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

import { Button, Col, Container, Form, Row } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faBuilding, faEnvelopeOpenText, faPhone, faClock } from '@fortawesome/free-solid-svg-icons';

export function ContactForm() {

  const form = useRef();
  const captcha = useRef(null);

  const [captchaValid, setCaptchaValid] = useState(null)

  const cancelForm = () => {
    document.getElementById("contactForm").reset();
  }

  const onChange = () => {
    if(captcha.current.getValue()) {
      setCaptchaValid(true)
    };
  }

  const sendEmail = (e) => {
    e.preventDefault();

    if(captcha.current.getValue()) {
      emailjs.sendForm('service_kc06xtm', 'template_ip2sggo', form.current, 'LA84gocFJau3FoLox')
      .then((result) => {
        cancelForm();
        setCaptchaValid(true);
        alert('Correo enviado!')
      }, (error) => {
        console.log(error.text);
      })
    } else {
      console.log("Aceptar Captcha")
      setCaptchaValid(false)
    }    
  }

  return (
    <Container fluid className="bg-white">
      <Container>
        <Row>
          <Col className="col-12 col-lg-8">
            <Row>
              <Col className="col-lg-6">
                <div className="d-flex flex-column h-100 p-xl-5" style={{ backgroundColor: '#1c3c83' }}>
                  <h4 className="text-center text-white pt-5 pt-xl-0 mb-4">Local Comercial</h4>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faBuilding} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestro local</h4>
                      <p className="m-0 text-white">Av. Benito de Miguel 820, Junín, BS.AS.</p>
                    </div>
                  </div>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestro correo</h4>
                      <p className="m-0 text-white">ventaseycdistribuidora@gmail.com</p>
                    </div>
                  </div>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faPhone} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestros numeros</h4>
                      <p className="m-0 text-white">+54 0236 154673657</p>
                      <p className="m-0 text-white">+54 0236 154674811</p>
                    </div>
                  </div>
                  <div className="p-4">
                    <FontAwesomeIcon icon={faClock} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestros horarios</h4>
                      <p className="m-0 text-white">Lunes a Viernes 09:00 a 17:00</p>
                      <p className="m-0 text-white">Sábados 09:00 a 13:00</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="col-lg-6">
                <div className="d-flex flex-column h-100 mt-5 mt-md-0 p-xl-5" style={{ backgroundColor: '#1c3c83' }}>
                  <h4 className="text-center text-white pt-5 pt-xl-0 mb-4">Parque Industrial</h4>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faBuilding} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestro local</h4>
                      <p className="m-0 text-white">D. Muscariello Esq. R. Riva (Parque Industrial Junín)</p>
                    </div>
                  </div>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestro correo</h4>
                      <p className="m-0 text-white">eycdistribuidora@gmail.com</p>
                    </div>
                  </div>
                  <div className="p-4 mb-4">
                    <FontAwesomeIcon icon={faPhone} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestros numeros</h4>
                      <p className="m-0 text-white">+54 0236 154674811 (Ventas)</p>
                      <p className="m-0 text-white">+54 0236 154673657 (Pedidos)</p>
                      <p className="m-0 text-white">+54 0236 154455526 (Asesoramiento)</p>
                    </div>
                  </div>
                  <div className="p-4">
                    <FontAwesomeIcon icon={faClock} size={'2x'} color='#8c9cc0' className="align-self-center m-0 me-3 d-none"></FontAwesomeIcon>
                    <div className="text-center">
                      <h4 style={{ color: '#8c9cc0' }}>Nuestros horarios</h4>
                      <p className="m-0 text-white">Lunes a Viernes 07:30 a 16:00</p>
                      <p className="m-0 text-white">Sábados 08:00 a 12:00</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col className="col-12 col-lg-4 my-5 py-5">
            <Form name="sentMessage" id="contactForm" noValidate="novalidate" ref={form} onSubmit={sendEmail}>
              <Form.Group>
                <Form.Control type="text" className="p-4" id="name" name="user_name" placeholder="Tu nombre" required data-validation-required-message="Por favor, ingresar su nombre" />
                <p className="help-block text-danger"></p>
              </Form.Group>
              <Form.Group>
                <Form.Control type="email" className="p-4" id="email" name="user_email" placeholder="Tu correo" required data-validation-required-message="Por favor, ingresar su correo electronico" />
                <p className="help-block text-danger"></p>
              </Form.Group>
              <Form.Group>
                <Form.Control type="text" className="p-4" id="subject" name="subject" placeholder="Tu asunto" required data-validation-required-message="Por favor, ingresar el asunto de su mensaje" />
                <p className="help-block text-danger"></p>
              </Form.Group>
              <Form.Group>
                <Form.Control className="p-4" rows="6" id="message" name="message" placeholder="Tu mensaje" required data-validation-required-message="Por favor, ingresar su mensaje"></Form.Control>
                <p className="help-block text-danger"></p>
              </Form.Group>
              <Form.Group style={{overflow: 'hidden'}}>
                <ReCAPTCHA 
                  ref={captcha}
                  sitekey="6LdvPZshAAAAAN22EGF8cSkZI5X8tYcH1RJet_J8" 
                  onChange={onChange}   
                />
              </Form.Group>
              {captchaValid === false && <p className="mt-2" style={{color: 'red'}}>Por favor, completa el captcha</p>}
              <Button className="py-3 px-5 mt-4" type="submit" id="sendMessageButton" style={{ backgroundColor: '#1c3c83', border: '1px solid #1c3c83' }}>Enviar mensaje</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}