import { useEffect, useState } from "react";

export const useRubros = (initialState) => {
    const [rubrosWithExpanded, setRubrosWithExpanded] = useState([])

    useEffect(() => {
        setRubrosWithExpanded(initialState.map(rubro => ({
            ...rubro,
            isExpanded: false
        })))
    }, [initialState])

    const isRubroExpanded = (id) => {
        const rubro = rubrosWithExpanded.find(rubro => rubro.id === id)
        return rubro ? rubro.isExpanded : false
    }

    const updateRubroExpanded = (id) => {
        setRubrosWithExpanded(rubrosWithExpanded.map(rubro => {
            if(rubro.id === id) {
                return {
                    ...rubro,
                    isExpanded: !rubro.isExpanded
                }
            }
            return rubro
        }))
    }

    const findSubrubro = (idSubrubro) => {
        return rubrosWithExpanded
            .map(rubro => rubro.subrubros)
            .flat()
            .find(subrubro =>  subrubro.id === idSubrubro)
    }

    const getNombreSubrubro = (idSubrubro) => {
        const subrubro = findSubrubro(idSubrubro)
        return subrubro ? subrubro.nombre : ''
    }

    return {
        rubrosWithExpanded,
        isRubroExpanded,
        updateRubroExpanded,
        getNombreSubrubro
    }
}
