import React from "react";
import { useState, useContext, useEffect } from 'react';

import { Container, Row, Col, Pagination, PageItem } from "react-bootstrap";
import { ProductPage } from "../pure/products/productPage";
import { SidebarFilter } from "../pure/products/sidebarFilter";

import { useApi } from '../../hooks/useApi';
import { FiltersContext } from "../../context/FilterContext";

export function Shop() {

  const itemsPorPagina = process.env.REACT_APP_PUBLIC_ITEMS_POR_PAGINA ? parseInt(process.env.REACT_APP_PUBLIC_ITEMS_POR_PAGINA) : 9

  const [rubros, setRubros] = useState([])

  const [currentOffset, setCurrentOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const { state: filters } = useContext(FiltersContext)
  const { getArticulos, getRubros } = useApi()

  const getNewRubros = async () => {
    getRubros().then((res) => {
      const filter = res.filter(hasSubrubros => hasSubrubros.subrubros.length > 0);
      setRubros(filter);
    })
  }

  useEffect(() => {
    getNewRubros()
  }, [])

  const getTotal = async () => {
    const articulos = await getArticulos(filters)
    setTotal(articulos.total)
  }

  useEffect(() => {
    getTotal()
    setCurrentOffset(0)
  }, [filters])

  const isThereNextPage = (currentOffset + itemsPorPagina) < total
  const isTherePreviousPage = currentOffset !== 0

  const goToNextPage = () => {
    if (isThereNextPage) {
      setCurrentOffset(currentOffset + itemsPorPagina)
    }
  }

  const goToPreviousPage = () => {
    if (isTherePreviousPage) {
      setCurrentOffset(currentOffset - itemsPorPagina)
    }
  }

  return (
    <Container className="py-5">
      <Row>
        <Col className="col-lg-3">
          <SidebarFilter rubros={rubros} />
        </Col>

        <Col className="col-12 col-lg-9">
          <div>
            <Row>
              <ProductPage offset={currentOffset} />
              {
                isThereNextPage &&
                <ProductPage
                  isRendered={false}
                  offset={currentOffset + itemsPorPagina} />
              }
            </Row>
            <Row>
              {
                (isThereNextPage || isTherePreviousPage) &&
                <Pagination className="justify-content-center">
                  <PageItem
                    disabled={!isTherePreviousPage}
                    onClick={goToPreviousPage}>
                    &larr; Anterior
                  </PageItem>
                  <PageItem
                    disabled={!isThereNextPage}
                    onClick={goToNextPage}>
                    Siguiente &rarr;
                  </PageItem>
                </Pagination>
              }
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}