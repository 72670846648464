import { useState, useEffect } from 'react'
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import { useDebouncedValue } from '../../../hooks/useDebouncedValue'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export function SearchFilter ({value, onChange}) {
    const [textValue, setTextValue] = useState(value)
    const deboncedValue = useDebouncedValue(textValue)

    useEffect(() => {
        onChange(deboncedValue)
    }, [deboncedValue])

    return (
        <div className="py-3">
            <h3 className="mb-4">Buscar</h3>
            <FormGroup>
                <InputGroup>
                    <FormControl 
                        type="text"
                        value={textValue}
                        onChange={(event) => {
                            if (event.target) setTextValue(event.target.value)
                        }}/>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                </InputGroup>
            </FormGroup>
        </div>
    )
}