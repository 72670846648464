import React from "react";
import Helmet from "react-helmet";
import { ContactForm } from "../component/pure/home/contactForm";
import { PageHeader } from "../component/pure/home/pageHeader";

export function Contact() {
  return (
    <>
      <Helmet>
        <title>EyC - Contacto</title>
      </Helmet>
      <PageHeader section='Contactanos' />
      <ContactForm />
    </>   
  )
}