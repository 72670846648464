import React from "react";

import { PageHeader } from "../component/pure/home/pageHeader";
import { OurService } from "../component/pure/home/ourService";
import { Col, Container, Row } from "react-bootstrap";

import construccionA from '../images/construccion_A.jpeg'
import construccionB from '../images/construccion_C.jpg'
import construccionE from '../images/construccion_E.jpg'

import designA from '../images/design_A.jpeg'
import designB from '../images/design_B.jpeg'
import { Helmet } from "react-helmet-async";

export function Service({ hidden }) {
  return (
    <>
      <Helmet>
        <title>EyC - Servicios</title>
      </Helmet>
      <PageHeader section="Servicios" />
      <OurService hidden='true' />

      <Container fluid className="py-5 bg-light">
        <Container className="py-5">
          <Row>
            <Col>
              <h6 className="font-weight-normal text-uppercase mb-3" style={{ color: '#1c3c83' }}>Local y regional</h6>
              <div className="position-relative">
                <div className="sectionTitleBefore"></div>
                <h1 className="mb-4 sectionTitle">Servicio de entregas</h1>
              </div>
              <p>
                In porttitor, dui et tincidunt maximus, orci ex eleifend nulla, in rhoncus magna lorem id felis. Proin urna nulla, posuere sit amet ipsum in, scelerisque imperdiet tortor. Mauris eros massa, interdum vitae lorem a, fermentum fermentum diam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce pharetra dolor ac nunc pellentesque, id feugiat nunc tempus. Nullam mauris lorem, hendrerit nec lorem sed, egestas tincidunt nisi.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 col-lg-4 mb-3">
              <div>
                <img className="img-fluid" src={construccionA} alt='Entrega A'></img>
              </div>
            </Col>
            <Col className="col-12 col-lg-4 mb-3">
              <div>
                <img className="img-fluid" src={construccionB} alt="Entrega B"></img>
              </div>
            </Col>
            <Col className="col-12 col-lg-4">
              <div>
                <img className="img-fluid" src={construccionE} alt="Entrega C"></img>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="py-5">
        <Container className="py-5">
          <Row>
            <Col className="col-12 col-md-7">
              <Row>
                <Col className="col-12 col-lg-6">
                  <div>
                    <img className="img-fluid mb-3" src={designA} alt="Consejo A"></img>
                  </div>
                </Col>
                <Col className="col-12 col-lg-6">
                  <div>
                    <img className="img-fluid" src={designB} alt="Consejo B"></img>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-md-5">
              <div className="pt-5">
                <h6 className="font-weight-normal text-uppercase mb-3" style={{ color: '#1c3c83' }}>Creatividad y calidad</h6>
                <div className="position-relative">
                  <div className="sectionTitleBefore"></div>
                  <h1 className="mb-4 sectionTitle">Atencion personalizada</h1>
                </div>
                <p>
                  In porttitor, dui et tincidunt maximus, orci ex eleifend nulla, in rhoncus magna lorem id felis. Proin urna nulla, posuere sit amet ipsum in, scelerisque imperdiet tortor. Mauris eros massa, interdum vitae lorem a, fermentum fermentum diam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Fusce pharetra dolor ac nunc pellentesque, id feugiat nunc tempus.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <style jsx="true">{`
        .sectionTitleBefore  {
          position: absolute;
          content: '';
          top: 10px;
          left: 0;
          width: 45px;
          height: 3px;
          background: #1c3c83
        }

        .sectionTitle {
          position: relative;
          padding-left: 60px
        }
      `}</style>
    </>
  )
}