import React from "react";

import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Skeleton from 'react-loading-skeleton'

export function ProductCard({ slug, name = '', image = '', isLoading = false }) {
  return (
    <>
      <Col className="align-self-center col-md-4 offset-md-0 col-xs-6">
        <Card style={{ margin: '20px 0' }}>
          {
            !isLoading
              ? 
                <div className={'product_image-container'}>
                  <div className={'product-image'} 
                    style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_SISTEMA_IMAGE_URL}${image})` }}>
                  </div>
                </div>
              : <div><Skeleton /></div>
          }
          <Card.Body className="product-details">
            {!isLoading ? <Card.Title className="text-center" style={{padding: '0 20px'}}>{name}</Card.Title> : <Skeleton count={2} />}
            {
              !isLoading &&
              <>
                <div className="text-center">
                  <Link to={slug}>
                    <FontAwesomeIcon icon={faSearch} size="1x" color='#1c3c83' />
                  </Link>
                </div>
              </>
            }
          </Card.Body>
        </Card>
      </Col>
      <style jsx="true">{`
            .product-image {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 100%;
                height: 200px;
            }

            .product-details {
                padding: 5px;
                background: #f7f7f7;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 130px;
              }

            .product_image-container {
              padding: 20px 0;
            }
      `}</style>
    </>
  )
}