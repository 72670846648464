import { useContext } from "react";
import useSWR from 'swr';

import { Row } from 'react-bootstrap'

import { ProductCard } from './productCard';
import { FiltersContext } from '../../../context/FilterContext';
import { api } from '../../../api/api';

export function ProductPage ({offset, isRendered = true}) {
  const { state: filters } = useContext(FiltersContext);
  const itemsPorPagina = process.env.REACT_APP_PUBLIC_ITEMS_POR_PAGINA && parseInt(process.env.REACT_APP_PUBLIC_ITEMS_POR_PAGINA);
  
  const fetcher = async (key, filters) => {
    const response = await api.post(`${process.env.REACT_APP_PUBLIC_SISTEMA_URL}/${key}`, {
      ...filters
    })
    return response.data
  }
  const { data, error } = useSWR([`articulosweb?offset=${offset}&limit=${itemsPorPagina}`, filters], fetcher);

  if (error) return <div style={{ display: !isRendered ? 'none' : 'block' }}>Ocurrió un error. Intentá de nuevo más tarde</div>
  if (!data) {
    return (
      <div style={{ display: !isRendered ? 'none' : 'block' }}>
        {
          Array.from(Array(itemsPorPagina).keys()).map(i => <ProductCard key={i} slug={'slug'} isLoading={true} />)
        }
      </div>
    )
  }

  return (
    <div style={{ display: !isRendered ? 'none' : 'block' }}>
      <Row>
      {
        data.results.map(({ id, slug, titulo, imagenPrincipal }) => <ProductCard key={id} slug={slug} name={titulo} image={imagenPrincipal} /> )
      }
      </Row>
    </div>
  )
}