import React, { useState, useEffect } from "react";

import { BottomBar } from "../pure/navBar/bottomBar";
import NavResponsive from '../pure/navBar/navResponsive'; 

export function NavBar() {

  const [width, setWindowWidth] = useState(0)

  useEffect(() => {
      updateDimensions();

      window.addEventListener("resize", updateDimensions);

      return () => 
          window.removeEventListener("resize", updateDimensions);
  }, [])

  const updateDimensions = () => {
      const width = window.innerWidth
      setWindowWidth(width)
  }

  const responsive = {
    showBottomNavMenu: width > 991
  }

  return (
    <div className="fixed-top">
      <NavResponsive showBoxShadow={responsive.showBottomNavMenu}/>
      <BottomBar showBottomNavMenu={responsive.showBottomNavMenu} />
    </div>   
  )
}
