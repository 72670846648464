import React from "react";
import { Link } from "react-router-dom";

const linkStyle = {
    textDecoration: "none",
    color: "black",
    margin: "0"
}

const LinkTel = ({ mobile, showTel }) => {
    const telCall = `tel:${mobile}`;

    return (
        <Link
            to=''
            onClick={(e) => {
                window.location.href = telCall;
                e.preventDefault();
            }}
            style={linkStyle}
        >
            {showTel}
        </Link>
    );
};

export default LinkTel;