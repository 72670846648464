import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import NewPageToTop from './component/helpers/newPageToTop';
import { FilterProvider } from './context/FilterContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FilterProvider>
      <BrowserRouter>
        <NewPageToTop />
        <App />
      </BrowserRouter>
    </FilterProvider>
  </React.StrictMode>
);
