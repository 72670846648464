import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faPenRuler, faBuilding, faSplotch, faHandshake } from "@fortawesome/free-solid-svg-icons";

const whiteColor = {
  color: 'white'
}

const sectionTitleBefore = {
    position: 'absolute',
    content: `''`,
    top: '10px',
    left: 0,
    width: '45px',
    height: '3px',
    background: '#1c3c83'
}

const sectionTitle = {
    position: 'relative',
    paddingLeft: '60px'
}

export function AboutUs() {
  return (
    <Container fluid className="bg-light">
        <Container>
            <Row>
                <Col className="col-lg-5">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 py-5 px-3" style={{backgroundColor: '#1c3c83'}}>
                        <FontAwesomeIcon icon={faBuilding} size='6x' className="my-4" style={whiteColor} />
                        <h4 className="display-3 mb-3" style={whiteColor}>Camino</h4>
                        <h1 className="m-0 text-center" style={whiteColor}>hacia los 20 años</h1>
                    </div>
                </Col>
                <Col className="col-lg-7 m-0 my-lg-5 pt-5 pb-5 pb-lg-2 ps-lg-5">
                    <h6 className="font-weight-normal text-uppercase mb-3" style={{color: '#1c3c83'}}>Conocenos</h6>
                    <div className="position-relative">
                        <div style={sectionTitleBefore}></div> 
                        <h1 className="mb-4" style={sectionTitle}>Vendemos materiales para la construcción al mejor precio.</h1>                
                    </div>       
                    <p>Representando a las mejores marcas con calidad de atención. Contamos con diversas formas de pago con el mejor precio a nuestro alcance.</p>
                    <Row className="py-2">
                        <Col className="col-12 col-lg-6">
                            <div className="d-flex align-items-center mb-4">
                                <div className="m-0 me-3">
                                  <FontAwesomeIcon icon={faHandshake} color='#1c3c83' size='2x' />
                                </div>
                                <h5 className="m-0">Asesoramiento previo y durante la obra</h5>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <div className="d-flex align-items-center mb-4">
                                <div className="m-0 me-3">
                                  <FontAwesomeIcon icon={faHouse} color='#1c3c83' size='2x' />
                                </div>              
                                <h5 className="m-0">Materiales gruesos de obra</h5>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <div className="d-flex align-items-center mb-4">
                                <div className="m-0 me-3">
                                  <FontAwesomeIcon icon={faSplotch} color='#1c3c83' size='2x' />
                                </div>
                                <h5 className="m-0">Productos de primer nivel</h5>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <div className="d-flex align-items-center mb-4">
                                <div className="m-0 me-3">
                                  <FontAwesomeIcon icon={faPenRuler} color='#1c3c83' size='2x' />
                                </div>
                                <h5 className="m-0">Diseño e innovación para el interior de tu hogar</h5>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Container>
  )
}