import React from "react";

import { Col, Container, Row } from 'react-bootstrap'

import testimonialA from './../../../images/testimonialA.jpg';
import testimonialB from './../../../images/testimonalB.png';
import testimonialC from './../../../images/testimonialC.png';
import testimonialD from './../../../images/testimonialD.png';

import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const sectionTitleBefore = {
    position: 'absolute',
    content: `''`,
    top: '10px',
    left: 0,
    width: '45px',
    height: '3px',
    background: '#1c3c83'
}

const sectionTitle = {
    position: 'relative',
    paddingLeft: '60px'
}

export function Testimonials() {
    return (
        <Container fluid className="bg-light">
            <Container className="py-5">
                <Row>
                    <h6 className="font-weight-normal text-uppercase mb-3 pt-5" style={{ color: '#1c3c83' }}>Testimonios</h6>
                    <div className="position-relative">
                        <div style={sectionTitleBefore}></div>
                        <h1 className="mb-4" style={sectionTitle}>¿Qué opinan nuestros clientes?</h1>
                    </div>
                </Row>
                <Row>
                    <Col className="col-12 col-md-6 col-xl-3 py-5 pr-md-5">
                        <div className="d-flex justify-content-center pb-3">
                            <img className="img-fluid rounded-circle" src={testimonialA} alt="Alejandro Camilletti" style={{ maxWidth: "128px", maxHeigth: "128px" }} />
                        </div>
                        <h5 className="text-center">Alejandro Camilletti</h5>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                        </p>
                        <p className="m-0 text-center" style={{ fontStyle: "italic" }}>"Todo el profesionalismo para sugerir cualquier solución, en mi caso, con Retak. Un aliado permanente de cualquier obra."</p>
                    </Col>

                    <Col className="col-12 col-md-6 col-xl-3 py-5 pr-md-5">
                        <div className="d-flex justify-content-center pb-3">
                            <img className="img-fluid rounded-circle" src={testimonialB} alt="Diego Puentes" style={{ maxWidth: "128px", maxHeigth: "128px" }} />
                        </div>
                        <h5 className="text-center">Diego Puentes</h5>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                        </p>
                        <p className="m-0 text-center" style={{ fontStyle: "italic" }}>"Excelente atención, calidad y humildad."</p>
                    </Col>
                    <Col className="col-12 col-md-6 col-xl-3 py-5 pr-md-5">
                        <div className="d-flex justify-content-center pb-3">
                            <img className="img-fluid rounded-circle" src={testimonialC} alt="Pedro Serratore" style={{ maxWidth: "128px", maxHeigth: "128px" }} />
                        </div>
                        <h5 className="text-center">Pedro Serratore</h5>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                        </p>
                        <p className="m-0 text-center" style={{ fontStyle: "italic" }}>"Muy buena atención. Te resuelven los problemas habituales de rapides de entrega"</p>
                    </Col>
                    <Col className="col-12 col-md-6 col-xl-3 py-5 pr-md-5">
                        <div className="d-flex justify-content-center pb-3">
                            <img className="img-fluid rounded-circle" src={testimonialD} alt="Elma Barrera" style={{ maxWidth: "128px", maxHeigth: "128px" }} />
                        </div>
                        <h5 className="text-center">Elma Barrera</h5>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD700"}} />
                        </p>
                        <p className="m-0 text-center" style={{ fontStyle: "italic" }}>"Excelente atención y su rapidez en llevar materiales"</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}