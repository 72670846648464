import React from "react";

import { Outlet } from "react-router-dom";

import { NavBar } from "../component/container/navBar";
import { Footer } from "../component/pure/home/footer";
import { ScrollToTop } from "../component/helpers/scrollToTop";

export function Layout() {
    return (
        <>
            <ScrollToTop />
            <NavBar />
            <Outlet />
            <Footer />
        </>
    )
}