import React from "react";

import { PageHeader } from "../component/pure/home/pageHeader";
import { Shop } from "../component/container/shop";
import { Helmet } from "react-helmet-async";
 
export function Commerce() {
  return (
    <>
      <Helmet>
        <title>EyC - Productos</title>
      </Helmet>
      <PageHeader section="Productos" />
      <Shop />
    </>
  )
}