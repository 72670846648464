import React from "react";
import { Container, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

export function PageError() {
    return (
        <Container fluid style={{paddingTop: '175px'}}>
            <Container>
                <Col className="text-center py-5">
                    <FontAwesomeIcon icon={faTriangleExclamation} size="4x" color="#0d6efd" className="my-3"/>
                    <h2 className="pb-5">Error 404</h2>
                    <h2>Ups! Algo salio mal</h2>
                    <p>La pagina donde intentas navegar no existe. Vuelve al inicio</p>
                    <Link to="/"><Button className="btn-lg mtJAJ-5">Inicio</Button></Link>
                </Col>
            </Container>
        </Container>
    )
}