import React from 'react';
import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { Layout } from './pages/layout';
import { Home } from './pages/home';
import { About } from "./pages/about";
import { Service } from './pages/service';
import { Commerce } from './pages/commerce';
import { Contact } from './pages/contact';
import { PageError } from './pages/error';
import { Product } from './pages/product';

function App() {
  const helmetContext = {};
  
  return (
    <HelmetProvider context={helmetContext}>
      <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="nosotros" element={<About />} />
            <Route path="servicios" element={<Service />} />
            <Route path="productos" element={<Commerce />} />
            <Route path="productos/:slug" element={<Product />} />
            <Route path="contacto" element={<Contact />} />
            <Route path="*" element={<PageError />} />
          </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
