import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";

import { Link } from 'react-router-dom';
 
import local from './../../../images/local.jpeg'

const sectionTitleBefore = {
    position: 'absolute',
    content: `''`,
    top: '10px',
    left: 0,
    width: '45px',
    height: '3px',
    background: '#1c3c83'
}

const sectionTitle = {
    position: 'relative',
    paddingLeft: '60px'
}

const containImg = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
}

export function WhyUs({hidden}){
  return (
    <Container fluid className="bg-light">
        <Container className="py-5">
            <Row>
                <Col className="col-12 col-md-6 col-lg-7 mt-5 py-5 pr-lg-5">
                    <h6 className="font-weight-normal text-uppercase mb-3" style={{color: '#1c3c83'}}>¿Por qué elegirnos?</h6>
                    <div className="position-relative">
                        <div style={sectionTitleBefore}></div>
                        <h1 className="mb-4" style={sectionTitle}>Más de 18 años de experiencia en el rubro.</h1>
                    </div>         
                    <p className="mb-4">Desde nuestros inicios en el 2005, nuestra empresa familiar siempre ha buscado ofrecer el mejor servicio posible a nuestros clientes en todo momento. Desde el asesoramiento hasta la entrega de los materiales.</p>
                    <ul className="list-inline">
                        <li><h5><FontAwesomeIcon icon={faSquareCheck} size='1x' color='#1c3c83' className="me-3" />18+ años de experiencia</h5></li>
                        <li><h5><FontAwesomeIcon icon={faSquareCheck} size='1x' color='#1c3c83' className="me-3" />La mejor relación precio calidad</h5></li>
                        <li><h5><FontAwesomeIcon icon={faSquareCheck} size='1x' color='#1c3c83' className="me-3" />Atención personalizada</h5></li>
                    </ul>
                    { hidden ? <></> :  <Link to="/nosotros"><Button className="mt-3 py-2 px-4" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83'}}>Saber más</Button></Link> }
                </Col>
                <Col className="col-12 col-md-6 col-lg-5">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 overflow-hidden">
                        <img className="h-100" style={containImg} src={local} alt="" />
                    </div>
                </Col>
            </Row>
        </Container>
    </Container>
  )
}