import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

const sectionTitleBefore = {
    position: 'absolute',
    content: `''`,
    top: '10px',
    left: 0,
    width: '45px',
    height: '3px',
    background: '#1c3c83'
}

const sectionTitle = {
    position: 'relative',
    paddingLeft: '60px'
}

export function OurService({hidden}){
  return (
    <Container fluid className="py-5">
        <Container className="py-5">
            <Row>
                <Col className="col-12 col-md-12 col-lg-6 pr-lg-5">
                    <h6 className="font-weight-normal text-uppercase mb-3" style={{color: '#1c3c83'}}>Nuestros servicios</h6>
                    <div className="position-relative">
                        <div style={sectionTitleBefore}></div>
                        <h1 className="mb-4" style={sectionTitle}>Distribucion y venta en toda la región, con asesoramiento sobre cada uno de los productos, logística propia, compromiso y seriedad.</h1>
                    </div>
                    <p>Desde hace mas de una década, atendemos a clientes de nuestra ciudad y de la zona para brindarles el mayor asesoramiento y soluciones para la correcta utilización de los materiales suministrados.</p>
                    {hidden ? <></> : <Link to="/servicios"><Button className="mt-3 py-2 px-4" style={{backgroundColor: '#1c3c83', border: '1px solid #1c3c83'}}>Ver más</Button></Link>}
                </Col>
                <Col className="col-12 col-lg-6 p-0 pt-5 pt-md-0">
                    <Row>
                        <Col className="col-12 col-lg-6 mb-3 mb-lg-0">
                            <div className="d-flex flex-column text-center bg-light mx-3 p-4">
                                <FontAwesomeIcon icon={faCreditCard} className="m-4" color='#1c3c83' size='3x' />
                                <h5 className="mb-3">Venta minorista</h5>
                                <p className="m-0">Compra toda tu obra en un solo lugar con variedades de forma de pago. Brindamos soluciones con la transparencia de un sistema de gestión de seguimiento para mejor control y un servicio de entrega que nos identifican.</p>
                            </div>
                        </Col>
                        <Col className="col-12 col-lg-6">
                            <div className="d-flex flex-column text-center bg-light mx-3 p-4">
                                <FontAwesomeIcon icon={faUsers} className="m-4" color='#1c3c83' size='3x' />
                                <h5 className="mb-3">Venta mayorista</h5>
                                <p className="m-0">Contamos con una flota de camiones disponible para realizar las entregas en Junin y la zona.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </Container>
  )
}