import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

export function PageHeader({ section, isASubCategory, subCategory, slugCategory }) {

    const [width, setWindowWidth] = useState(0)

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () =>
            window.removeEventListener('resize', updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const isMenuVisible = width > 991

    const marginTopSize = {
        marginTop: isMenuVisible ? '168px' : '110px'
    }

    return (
        <Container fluid className="bg-dark-blue py-5" style={marginTopSize}>
            <Container className="py-5">
                <Row className="align-items-center py-4">
                    {slugCategory ?
                        <>
                            <Col className="col-12 col-md-6 text-center text-md-left">
                                <h1 className="mb-4 mb-md-0 text-uppercase" style={{ color: '#8c9cc0' }}>{section}</h1>
                            </Col>
                            <Col className="col-12 col-md-6 text-center text-md-right">
                                <div className="d-inline-flex align-items-center">
                                    <Button className="btn-outline-light disabled" to="">{section}</Button>
                                    {
                                        isASubCategory &&
                                        <>
                                            <FontAwesomeIcon icon={faAnglesRight} color='#8c9cc0' className="mx-2"></FontAwesomeIcon>
                                            <Button className="btn-outline-light disabled" to="">{subCategory}</Button>
                                        </>
                                    }
                                </div>
                            </Col>
                        </>
                        : <>
                        <Col className="col-12 col-md-6 text-center text-md-left">
                                <h1 className="mb-4 mb-md-0 text-uppercase" style={{ color: '#8c9cc0' }}>{section}</h1>
                            </Col>
                            <Col className="col-12 col-md-6 text-center text-md-right">
                                <div className="d-inline-flex align-items-center">
                                    <Link className="btn" style={{ backgroundColor: '#8c9cc0', border: '1px solid #8c9cc0', color: 'black' }} to="/">Inicio</Link>
                                    <FontAwesomeIcon icon={faAnglesRight} color='#8c9cc0' className="mx-2"></FontAwesomeIcon>
                                    <Button className="btn-outline-light disabled" to="">{section}</Button>
                                    {
                                        isASubCategory &&
                                        <>
                                            <FontAwesomeIcon icon={faAnglesRight} color='#8c9cc0' className="mx-2"></FontAwesomeIcon>
                                            <Button className="btn-outline-light disabled" to="">{subCategory}</Button>
                                        </>
                                    }
                                </div>
                            </Col>
                        </>
                    }
                </Row>
            </Container>
        </Container>
    )
}