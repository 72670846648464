import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { NavLink, Link } from "react-router-dom";

import logo from '../../../images/logo_A.png'

import './style.css'

let activeStyle = {
    textDecoration: 'none',
    color: '#8c9cc0'
}

let desactiveStyle = {
    textDecoration: "none",
    color: 'white'
}

function NavResponsive({showBoxShadow}) {

    const shadow = {
        boxShadow : showBoxShadow ? 'none' : 'rgb(0 0 0 / 47%) 0px 8px 5px 0px'
    }

    return (
        <Navbar className='bg-dark-blue py-3 py-lg-0 p-3 p-lg-5' style={shadow} expand="lg">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            src={logo}
                            width='75px'
                            className="d-inline-block align-top"
                            alt="Edit Logo"
                            style={{ margin: '10px 0' }}
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" style={{ color: 'white', backgroundColor: 'white' }} />
                <Navbar.Collapse className="justify-content-end px-3" id="navbarScroll">
                    <Nav
                        className="ml-auto py-0"
                        style={{ maxHeight: '200px' }}
                    >
                        <NavLink to="/" className='text-center pe-lg-2' style={({ isActive }) => isActive ? activeStyle : desactiveStyle}>Inicio</NavLink>
                        <NavLink to="/nosotros" className='text-center px-lg-2' style={({ isActive }) => isActive ? activeStyle : desactiveStyle}>Nosotros</NavLink>
                        <NavLink to="/servicios" className='text-center px-lg-2' style={({ isActive }) => isActive ? activeStyle : desactiveStyle}>Servicios</NavLink>
                        <NavLink to="/productos" className='text-center px-lg-2' style={({ isActive }) => isActive ? activeStyle : desactiveStyle}>Productos</NavLink>
                        <NavLink to="/contacto" className='text-center ps-lg-2' style={({ isActive }) => isActive ? activeStyle : desactiveStyle}>Contacto</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavResponsive;