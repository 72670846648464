import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin, faPhone, faEnvelope, faChevronRight, faClock } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import { Link } from 'react-router-dom';

const circleStyle = {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    border: '1px solid white'
}

const bgDark = {
  backgroundColor: '#181818'
}

export function Footer() {
  return (
    <Container fluid style={bgDark}>
        <Container className="text-white py-5 px-sm-3 px-md-5">
            <Row>
                <Col className="col-12 col-md-6 col-lg-5 mb-5">
                    <h4 className="mb-4" style={{color: '#8c9cc0'}}>Local Comercial</h4>
                    <p><FontAwesomeIcon icon={faLocationPin} className="me-2" />Av. Dr. Benito de Miguel 820 Junín, Bs. As.</p>
                    <p><FontAwesomeIcon icon={faPhone} className="me-2" />+54 0236 154673657</p>
                    <p><FontAwesomeIcon icon={faPhone} className="me-2" />+54 0236 154674811</p>
                    <p><FontAwesomeIcon icon={faEnvelope} className="me-2" />ventaseycdistribuidora@gmail.com</p>
                    <p><FontAwesomeIcon icon={faClock} className="me-2" />Lunes a Viernes de 09:00 hs a 17:00 hs <br /> <span className="ms-4">Sábados de 09:00 hs a 13:00 hs</span></p>
                    <div className="d-flex justify-content-start mt-3">
                        <div className="text-center px-0 me-2" style={circleStyle}><a href="https://www.facebook.com/EyCMateriales" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faFacebook} size='1x' color="white" className="my-2" /></a></div>
                        <div className="text-center px-0 me-2" style={circleStyle}><a href="https://www.instagram.com/eycmateriales" target="_blank" rel="noreferrer noopener"><FontAwesomeIcon icon={faInstagram} size='1x' color="white" className="my-2" /></a></div>
                    </div>
                </Col>
                <Col className="col-12 col-md-6 col-lg-5 mb-5">
                <h4 className="mb-4" style={{color: '#8c9cc0'}}>Parque Industrial</h4>
                    <p><FontAwesomeIcon icon={faLocationPin} className="me-2" />D. Muscariello Esq. R. Riva (Parque Industrial Junín)</p>
                    <p><FontAwesomeIcon icon={faPhone} className="me-2" /><strong>Ventas:</strong> +54 0236 154674811</p>
                    <p><FontAwesomeIcon icon={faPhone} className="me-2" /><strong>Asesoramiento:</strong> +54 0236 154455526</p>
                    <p><FontAwesomeIcon icon={faPhone} className="me-2" /><strong>Expedición y pedidos:</strong> +54 0236 154673657</p>
                    <p><FontAwesomeIcon icon={faEnvelope} className="me-2" />eycdistribuidora@gmail.com</p>
                    <p><FontAwesomeIcon icon={faClock} className="me-2" />Lunes a Viernes de 07:30 hs a 16:00 hs <br /> <span className="ms-4">Sábados de 08:00 hs a 12:00 hs</span></p>
                </Col>
                <Col className="col-12 col-md-6 col-lg-2 mb-5">
                    <h4 className="mb-4" style={{color: '#8c9cc0'}}>Links</h4>
                    <div className="d-flex flex-column justify-content-start">
                        <Link to="/" className="text-white text-decoration-none mb-2"><FontAwesomeIcon icon={faChevronRight} className="me-2"/>Inicio</Link>
                        <Link to="/nosotros" className="text-white text-decoration-none mb-2"><FontAwesomeIcon icon={faChevronRight} className="me-2"/>Nosotros</Link>
                        <Link to="/servicios" className="text-white text-decoration-none mb-2"><FontAwesomeIcon icon={faChevronRight} className="me-2"/>Servicios</Link>
                        <Link to="/productos" className="text-white text-decoration-none mb-2"><FontAwesomeIcon icon={faChevronRight} className="me-2"/>Productos</Link>
                        <Link to="/contacto" className="text-white text-decoration-none"><FontAwesomeIcon icon={faChevronRight} className="me-2"/>Contactanos</Link>
                    </div>
                </Col>
            </Row>
            <hr />
            <p className="m-0 text-center text-white">
                &copy; <span className="text-white font-weight-bold" href="#">E&C Distribuidora</span>. Todos los derechos reservados. Desarrollado por <a className="text-white font-weight-bold" href="https://edit.com.ar/" target="_blank" rel="noreferrer noopener">EDIT</a>
            </p>
        </Container>
    </Container>
  )
}