import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";

import './scrollToTop.css';

export function ScrollToTop() {

    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="top-to-btn">
            {" "} 
            {showTopBtn && ( 
                <FontAwesomeIcon 
                    icon={faCircleChevronUp} 
                    size='1x' 
                    color='white' 
                    className="icon-position icon-style" 
                    onClick={goToTop} 
                />
            )}{" "}
        </div>
    )
}